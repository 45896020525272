.subtitle {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 1.1rem;
  color: rgb(97, 95, 95);
}

.pw-login {
  margin: 0 0.5rem;
  font-weight: 700;
  border-bottom: solid 2px black;
  transition: border-bottom 0.5s ease-in, color 0.3s ease;
}

.pw-login:hover {
  color: black;
  cursor: default;
}

.pw-login:active {
  color: black;
  cursor: default;
  border-bottom: none;
}
